import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

export default function FontSize({ activeTextField, handleFontSize }) {
  return (
    <FormControl variant="standard" sx={{ width: "5rem" }}>
      <Tooltip title="Font Size" placement="top-start">
        <TextField
          type="number"
          name={activeTextField.label}
          variant="standard"
          onChange={handleFontSize}
          value={activeTextField.fontSize}
        />
      </Tooltip>
    </FormControl>
  );
}
