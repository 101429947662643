import React from "react";
import { Image } from "react-konva";

export default function BackgroundImage({ width, height, image }) {
  return (
    <>
      {image && <Image image={image} width={width} height={height} draggable />}
    </>
  );
}
