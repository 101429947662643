import React from "react";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";

export default function FontFormat({ activeTextField, handleTextFormat }) {
  return (
    <ToggleButtonGroup
      exclusive
      value={activeTextField.fontStyle}
      onChange={(e, newFormats) =>
        handleTextFormat(e, newFormats, activeTextField.id)
      }
      color="primary"
      size="small"
      sx={{ height: "2rem" }}
    >
      <ToggleButton value="bold">
        <Tooltip title="Bold" placement="top">
          <FormatBoldIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="italic">
        <Tooltip title="Italics" placement="top">
          <FormatItalicIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
