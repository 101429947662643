import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function Background({
  handleLogoUpload,
  handleRemoveLogo,
  logoImg,
  logoPlacement,
  handleLogoSize,
  // handleLogoAnimate,
}) {
  return (
    <>
      <Typography
        sx={{
          fontSize: "14px",
          color: "white",
          fontWeight: "bold",
          mb: "2rem",
        }}
      >
        Logo Image (File Size Limit: 1mb)
      </Typography>
      <Button variant="contained" component="label" sx={{ mb: "1rem" }}>
        Upload
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          key={logoImg.length}
          onChange={handleLogoUpload}
        />
      </Button>
      {logoImg.length > 0 && (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRemoveLogo}
            sx={{ mb: "2rem" }}
          >
            Remove Logo
          </Button>
          <FormControl
            variant="filled"
            fullWidth
            sx={{ backgroundColor: "white", mb: "2rem" }}
          >
            <InputLabel>Logo Width</InputLabel>
            <Select
              name="Width"
              value={logoPlacement.width}
              label="Logo Width"
              onChange={handleLogoSize}
              size="small"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={125}>125</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={175}>175</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="filled"
            fullWidth
            sx={{ backgroundColor: "white", mb: "2rem" }}
          >
            <InputLabel>Logo Height</InputLabel>
            <Select
              name="Height"
              value={logoPlacement.height}
              label="Logo Height"
              onChange={handleLogoSize}
              size="small"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={125}>125</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={175}>175</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl
            variant="filled"
            fullWidth
            sx={{ backgroundColor: "white" }}
          >
            <InputLabel>Logo Animation</InputLabel>
            <Select
              name="Height"
              value={logoPlacement.animate}
              onChange={handleLogoAnimate}
              size="small"
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Logo_FLR"}>Fade Left to Right</MenuItem>
            </Select>
          </FormControl> */}
        </>
      )}
    </>
  );
}
