import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function ModalConfirmation({ error, handleDismiss }) {
  return (
    <Dialog open={error}>
      <DialogTitle>{"Something went wrong"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We couldn't fetch the necessary data at this time. Please try again.
        </DialogContentText>
        <DialogContentText>
          If the issue persist, please contact managing developer.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleDismiss} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
