import React from "react";
import Box from "@mui/material/Box";
import HeaderText from "./HeaderText";
import Background from "./Background";
import Logo from "./Logo";
import Templates from "./Templates";

export default function LargeSideNav({
  textBtn,
  backgroundBtn,
  logoBtn,
  templateBtn,
  textFieldState,
  handleTextChange,
  handleUpload,
  handleRemoveBackground,
  backgroundImg,
  handleLogoUpload,
  handleRemoveLogo,
  logoImg,
  logoPlacement,
  handleLogoSize,
  templateVal,
  handleTemplateChange,
  handleLogoAnimate,
}) {
  return (
    <Box display="flex" flexDirection="column" sx={{ padding: "2rem", overflow: "auto", maxHeight: `calc(100vh - 64px)` }}>
      {textBtn && (
        <HeaderText
          textFieldState={textFieldState}
          handleTextChange={handleTextChange}
        />
      )}
      {backgroundBtn && (
        <Background
          handleUpload={handleUpload}
          backgroundImg={backgroundImg}
          handleRemoveBackground={handleRemoveBackground}
        />
      )}
      {logoBtn && (
        <Logo
          handleLogoUpload={handleLogoUpload}
          handleRemoveLogo={handleRemoveLogo}
          logoImg={logoImg}
          logoPlacement={logoPlacement}
          handleLogoSize={handleLogoSize}
          handleLogoAnimate={handleLogoAnimate}
        />
      )}
      {templateBtn && (
        <Templates
          templateVal={templateVal}
          handleTemplateChange={handleTemplateChange}
        />
      )}
    </Box>
  );
}
