import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export default function ErrorDialog({ handleDismiss, submitError, submitErrorMessage }) {
  return (
    <Dialog open={submitError}>
      <DialogTitle>{"Something went wrong"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: "1rem" }}>
          We couldn't send your newsbreak at this time. Please try again.
        </DialogContentText>
        <DialogContentText sx={{ mb: "1rem" }}>
          If the issue persist, please download your newsbreak for the time being and contact SmartSpek at info@golocaldigitalbillboards.com.
        </DialogContentText>
        <Divider />
        <DialogContentText sx={{ mt: "1rem" }}>
          Technical Message: {submitErrorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleDismiss} variant="outlined">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
