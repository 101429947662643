// State for small side navigation
export const smallSideNavState = {
  textBtn: false,
  colorBtn: false,
  fontBtn: false,
  fontSizeBtn: false,
  backgroundBtn: true,
  uploadBtn: false,
  logoBtn: false,
  templateBtn: false,
};

// State for the text
export const textState = [
  {
    id: 1,
    label: "Text #1",
    value: "",
    color: "#000000",
    isActive: false,
    isColorActive: false,
    font: "Helvetica",
    fontSize: 64,
    fontStyle: "normal",
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 2,
    label: "Text #2",
    value: "",
    color: "#000000",
    isActive: false,
    isColorActive: false,
    font: "Helvetica",
    fontSize: 64,
    fontStyle: "normal",
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 3,
    label: "Text #3",
    value: "",
    color: "#000000",
    isActive: false,
    isColorActive: false,
    font: "Helvetica",
    fontSize: 64,
    fontStyle: "normal",
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 4,
    label: "Text #4",
    value: "",
    color: "#000000",
    isActive: false,
    isColorActive: false,
    font: "Helvetica",
    fontSize: 64,
    fontStyle: "normal",
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 5,
    label: "Text #5",
    value: "",
    color: "#000000",
    isActive: false,
    font: "Helvetica",
    fontStyle: "normal",
    isColorActive: false,
    fontSize: 64,
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 6,
    label: "Text #6",
    value: "",
    color: "#000000",
    isActive: false,
    font: "Helvetica",
    fontStyle: "normal",
    isColorActive: false,
    fontSize: 64,
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
  {
    id: 7,
    label: "Text #7",
    value: "",
    color: "#000000",
    isActive: false,
    font: "Helvetica",
    fontStyle: "normal",
    isColorActive: false,
    fontSize: 64,
    textAlign: "left",
    xCoordinate: 200,
    yCoordinate: 200,
    isDragging: false,
    animate: "",
    textWidth: 100,
  },
];

// State for logo
export const logoImgState = {
  xCoordinate: 50,
  yCoordinate: 50,
  height: 100,
  width: 100,
  isDragging: false,
  animate: "",
};

export const loadingMessage = "SmartSpek is loading";

export const videoCompileLoading = "SmartSpek is loading";
