import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function CircularIndeterminate({ props }) {
  return (
    <Backdrop
      open
      sx={{
        color: "#fff",
        height: "100%",
        zIndex: "2",
      }}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <CircularProgress
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <Typography variant="subtitle1" sx={{ maxWidth: "50rem", textAlign: "center", fontWeight: "bold" }}>
          {props}
        </Typography>
      </Stack>
    </Backdrop>
  );
}
