import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";

export default function TextWidth({ activeTextField, handleTextWidth }) {
  return (
    <FormControl variant="standard" sx={{ width: "5rem" }}>
      <Tooltip title="Text Width" placement="top">
        <Select
          name={activeTextField.label}
          onChange={handleTextWidth}
          value={activeTextField.textWidth}
        >
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={15}>15%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={25}>25%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={35}>35%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={45}>45%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={55}>55%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={65}>65%</MenuItem>
          <MenuItem value={70}>70%</MenuItem>
          <MenuItem value={75}>75%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={85}>85%</MenuItem>
          <MenuItem value={90}>90%</MenuItem>
          <MenuItem value={95}>95%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
        </Select>
      </Tooltip>
    </FormControl>
  );
}
