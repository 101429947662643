import React, { useRef, useEffect, useCallback } from "react";
import { Image } from "react-konva";
import Konva from "konva";

export default function Logo({
  width,
  height,
  image,
  onLogoDragStart,
  onLogoDragEnd,
  logoPlacement,
  isAnimated,
}) {
  const logoRef = useRef();

  // Fade left to right animation
  const fadeRight = useCallback(() => {
    const tween = new Konva.Tween({
      node: logoRef.current,
      x: logoPlacement.xCoordinate,
      opacity: 1,
      duration: 2,
      easing: Konva.Easings.Linear,
    });

    tween.play();
  }, [logoPlacement.xCoordinate]);

  useEffect(() => {
    if (isAnimated && logoPlacement.animate === "Logo_FLR") {
      fadeRight();
    }
  }, [isAnimated, logoPlacement.animate, fadeRight]);

  return (
    <>
      {image && (
        <Image
          ref={logoRef}
          image={image}
          width={width}
          height={height}
          x={
            isAnimated && logoPlacement.animate === "Logo_FLR"
              ? logoPlacement.xCoordinate - 100
              : logoPlacement.xCoordinate
          }
          y={logoPlacement.yCoordinate}
          draggable
          onDragStart={onLogoDragStart}
          onDragEnd={(e) => onLogoDragEnd(e)}
          opacity={isAnimated && logoPlacement.animate === "Logo_FLR" ? 0 : 1}
        />
      )}
    </>
  );
}
