import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function InviteModalConfirmation({
  handleCloseInviteModal,
  handleCreateUser,
  inviteModal,
  handleInviteEmail,
  inviteEmail,
  emailError,
}) {
  return (
    <Dialog open={inviteModal}>
      <DialogTitle>{"Invite a new user"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new user's email address. An email will be sent
          with a temporary password to the user's email.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="User's Email Address"
          fullWidth
          variant="outlined"
          sx={{ mt: "2rem" }}
          type="email"
          onChange={handleInviteEmail}
          value={inviteEmail}
          error={emailError}
          helperText={emailError ? `Invalid email format` : ""}
        />
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleCloseInviteModal} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleCreateUser}
          autoFocus
          variant="contained"
          disabled={emailError || inviteEmail.length === 0}
        >
          Invite user
        </Button>
      </DialogActions>
    </Dialog>
  );
}
