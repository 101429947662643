import React, { useState } from "react";
import SmallSideNav from "../SmallSideNav/SmallSideNav";
import LargeSideNav from "../LargeSideNav/LargeSideNav";
import Canvas from "../Canvas/Canvas";
import Box from "@mui/material/Box";
import UpperToolBox from "../UpperToolBox/UpperToolBox";
import SuccessDialog from "./SuccessDialog";
import FileExceed from "./FileExceed";
import LogoFileExceed from "./LogoFileExceed";
import { smallSideNavState } from "../Properties";

export default function Home({
  stageRef,
  textFieldState,
  submitSuccess,
  handleCloseSuccess,
  isAnimated,
  handleTextChange,
  onTextClick,
  onChangeComplete,
  handleColorActive,
  handleFont,
  handleFontSize,
  handleTextFormat,
  handleTextWidth,
  onTextDragStart,
  onTextDragEnd,
  handleAnimate,
  handleLogoUpload,
  handleRemoveLogo,
  onLogoDragStart,
  onLogoDragEnd,
  handleLogoSize,
  handleLogoAnimate,
  logoPlacement,
  logoImg,
  logoFileExceed,
  handleCloseLogoExceed,
}) {
  const [buttonState, setButtonState] = useState(smallSideNavState);
  const [backgroundImg, setBackgroundImg] = useState([]);
  const [templateVal, setTemplateVal] = useState("");
  const [fileExceed, setFileExceed] = useState(false);

  // Handle clicks from small side nav
  const handleClick = (btnName) => {
    setButtonState((prevState) => {
      return {
        ...prevState,
        textBtn: btnName === "text",
        backgroundBtn: btnName === "background",
        logoBtn: btnName === "logo",
        templateBtn: btnName === "templates",
      };
    });
  };

  // Handles the upload background image file
  const handleUpload = (event) => {
    const file = event.target.files[0];

    // If the file size exceeds the 4mb limit
    if (file.size > 4 * 1024 * 1024) {
      setFileExceed(true);
      return;
    }

    const imgUrl = URL.createObjectURL(file);
    setBackgroundImg(imgUrl);
  };

  // Handles the removal of background image
  const handleRemoveBackground = () => {
    setBackgroundImg([]);
  };

  // Handles template change
  const handleTemplateChange = (event) => {
    const { value } = event.target;
    setTemplateVal(value);
  };

  // Closes file exceeds dialog
  const handleCloseExceed = () => {
    setFileExceed(false);
  }

  return (
    <Box sx={{ height: `calc(100vh - 64px)`, backgroundColor: "#ebecf0" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "5rem 20rem 1fr",
          height: "100%",
        }}
      >
        <Box sx={{ backgroundColor: "#18191b" }}>
          <SmallSideNav handleClick={handleClick} {...buttonState} />
        </Box>
        <Box sx={{ backgroundColor: "#252627" }}>
          <LargeSideNav
            {...buttonState}
            handleTextChange={handleTextChange}
            handleUpload={handleUpload}
            textFieldState={textFieldState}
            backgroundImg={backgroundImg}
            handleRemoveBackground={handleRemoveBackground}
            handleLogoUpload={handleLogoUpload}
            handleRemoveLogo={handleRemoveLogo}
            logoImg={logoImg}
            logoPlacement={logoPlacement}
            templateVal={templateVal}
            handleTemplateChange={handleTemplateChange}
            handleLogoSize={handleLogoSize}
            handleLogoAnimate={handleLogoAnimate}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#ebecf0",
            display: "flex",
            flexDirection: "column",
            alignItems: "column",
            justifyContent: "center",
            pt: "6rem",
            pb: "2.8rem",
            pl: "6rem",
            pr: "6rem",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fff",
              width: "100%",
              height: "3rem",
              padding: ".6rem",
              zIndex: 1,
            }}
          >
            <UpperToolBox
              textFieldState={textFieldState}
              onChangeComplete={onChangeComplete}
              handleColorActive={handleColorActive}
              handleFont={handleFont}
              handleFontSize={handleFontSize}
              handleTextFormat={handleTextFormat}
              handleAnimate={handleAnimate}
              handleTextWidth={handleTextWidth}
            />
          </Box>
          
          <Canvas
            textFieldState={textFieldState}
            backgroundImg={backgroundImg}
            logoPlacement={logoPlacement}
            logoImg={logoImg}
            templateVal={templateVal}
            stageRef={stageRef}
            onTextDragStart={onTextDragStart}
            onTextDragEnd={onTextDragEnd}
            onTextClick={onTextClick}
            onLogoDragStart={onLogoDragStart}
            onLogoDragEnd={onLogoDragEnd}
            isAnimated={isAnimated}
          />
        </Box>
      </Box>

      <SuccessDialog
        submitSuccess={submitSuccess}
        handleCloseSuccess={handleCloseSuccess}
      />

      <FileExceed
        fileExceed={fileExceed}
        handleCloseExceed={handleCloseExceed}
      />

      <LogoFileExceed
        logoFileExceed={logoFileExceed}
        handleCloseLogoExceed={handleCloseLogoExceed}
      />
    </Box>
  );
}
