import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "@fontsource/lato/400.css";
import "@fontsource/crimson-pro/400.css";

export default function Landing() {
  const { isAuthenticated } = useAuth0();
  return (
    <Box
      sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing_background.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        p: "2rem 5rem",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <img
          src={`${process.env.PUBLIC_URL}/images/landing_logo.png`}
          alt="logo"
          style={{ height: "50px", width: "50px" }}
        />
        <Typography
          sx={{
            color: "#d9d9d9",
            fontWeight: "bold",
            fontSize: "32px",
            fontFamily: "crimson-pro",
          }}
        >
          SmartSpek
        </Typography>
      </Stack>

      <Stack
        justifyContent="space-around"
        direction="column"
        sx={{ height: "85%" }}
      >
        <Typography
          sx={{
            fontSize: "48px",
            color: "#F2913D",
            fontFamily: "Lato",
            fontWeight: "bold",
            width: "35rem",
          }}
        >
          Start turning your ideas into reality.
        </Typography>
        <Stack spacing={3}>
          <Typography
            sx={{
              fontSize: "20px",
              color: "white",
              fontFamily: "Lato",
              width: "30rem",
            }}
          >
            Sign in and get full access to develop your next digital newsbreak.
          </Typography>
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Stack>
      </Stack>
    </Box>
  );
}
