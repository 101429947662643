import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import "@fontsource/lato/400.css";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      sx={{
        width: "200px",
        height: "50px",
        backgroundColor: "#F2913D",
        fontSize: "20px",
        fontFamily: "lato",
        color: "black",
        "&:hover": {
          backgroundColor: "#F29E38",
        },
      }}
      variant="contained"
      onClick={() => loginWithRedirect()}
    >
      Get Started
    </Button>
  );
};

export default LoginButton;
