import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function SuccessDialog({ submitSuccess, handleCloseSuccess }) {
  return (
    <Dialog open={submitSuccess}>
      <DialogTitle>{"Success!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your newsbreak has been successfully sent to GoLocal Digital
          Billboards.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleCloseSuccess} variant="outlined">Close</Button>
      </DialogActions>
    </Dialog>
  );
}
