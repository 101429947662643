import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function Background({
  handleUpload,
  handleRemoveBackground,
  backgroundImg,
}) {
  return (
    <>
      <Typography
        sx={{
          fontSize: "14px",
          color: "white",
          fontWeight: "bold",
          mb: "2rem",
        }}
      >
        Background Image (File Size Limit: 4mb)
      </Typography>
      <Button variant="contained" component="label" sx={{ mb: "1rem" }}>
        Upload
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          key={backgroundImg.length}
          onChange={handleUpload}
        />
      </Button>
      {backgroundImg.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleRemoveBackground}
        >
          Remove Background
        </Button>
      )}
    </>
  );
}
