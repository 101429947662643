import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function Templates({ handleTemplateChange, templateVal }) {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "14px",
          color: "white",
          fontWeight: "bold",
          mb: "2rem",
        }}
      >
        Templates
      </Typography>
      <FormControl variant="filled" fullWidth sx={{ backgroundColor: "white" }}>
      <InputLabel>Template</InputLabel>
        <Select
          value={templateVal}
          label="Template"
          onChange={handleTemplateChange}
          size="small"
        > 
          <MenuItem value="">None</MenuItem>
          <MenuItem value={"Full_Page_Black"}>Full Page Black</MenuItem>
          <MenuItem value={"Full_Page_Red"}>Full Page Red</MenuItem>
          <MenuItem value={"Medium_Page_Black"}>Medium Page Black</MenuItem>
          <MenuItem value={"Medium_Page_Red"}>Medium Page Red</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
