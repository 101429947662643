import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function AdminTable({ users, handleModal, handleInviteModal }) {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        p: "5rem 5rem",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Manage Accounts
        </Typography>
        <Button
          variant="contained"
          onClick={handleInviteModal}
          startIcon={<AddIcon />}
        >
          Invite User
        </Button>
      </Stack>
      <TableContainer sx={{ mt: "2rem" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Email
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Account created on
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Last login
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Verified?
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                App Role
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Delete User
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.user_id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {moment(user.created_at).format("MM/DD/YYYY, h:mm a")}
                </TableCell>
                <TableCell>
                  {moment(user.last_login).format("MM/DD/YYYY, h:mm a")}
                </TableCell>
                <TableCell>
                  {user.email_verified ? (
                    <VerifiedUserOutlinedIcon color="success" />
                  ) : (
                    <CancelOutlinedIcon color="error" />
                  )}
                </TableCell>
                <TableCell>
                  {user.app_metadata?.role === "Admin" ? "Admin" : "User"}
                </TableCell>
                <TableCell>
                  {user.app_metadata?.role !== "Admin" && (
                    <DeleteIcon
                      sx={{
                        color: "grey",
                        "&:hover": { cursor: "pointer" },
                      }}
                      onClick={() => handleModal(user)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
