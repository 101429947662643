import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";

export default function FontStyle({ activeTextField, handleFont }) {
  return (
    <FormControl variant="standard" sx={{ width: "9rem" }}>
      <Tooltip title="Font" placement="top-start">
        <Select
          name={activeTextField.label}
          onChange={handleFont}
          value={activeTextField.font}
        >
          <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
          <MenuItem value="Courier New">Courier New</MenuItem>
          <MenuItem value="Futura">Futura</MenuItem>
          <MenuItem value="Georgia">Georgia</MenuItem>
          <MenuItem value="Helvetica">Helvetica</MenuItem>
          <MenuItem value="Impact">Impact</MenuItem>
          <MenuItem value="Palatino">Palatino</MenuItem>
          <MenuItem value="Rockwell">Rockwell</MenuItem>
          <MenuItem value="Tahoma">Tahoma</MenuItem>
          <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          <MenuItem value="Verdana">Verdana</MenuItem>
        </Select>
      </Tooltip>
    </FormControl>
  );
}
