import React, { useEffect, useState } from "react";
import axios from "axios";
import Progress from "../Progress";
import AdminTable from "./AdminTable";
import ModalConfirmation from "./ModalConfirmation";
import InviteModalConfirmation from "./InviteModalConfirmation";
import Error from "./Error";
import { loadingMessage } from "../Properties";

export default function Admin() {
  const [users, setUsers] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userToBeDeleted, setUserToBeDeleted] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/.netlify/functions/getUserList");
        setTimeout(() => {
          setUsers(response.data);
          setIsLoading(false);
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          setError(true);
          setIsLoading(false);
        }, 1500);
      }
    };
    fetchData();
  }, []);

  // Handles creation of new user
  const handleCreateUser = async () => {
    const email = inviteEmail;
    try {
      setIsLoading(true);
      setInviteModal(false);

      const response = await axios.post("/.netlify/functions/createUser", {
        email,
      });

      // If response is successful
      if (response.status >= 200 && response.status < 300) {
        // Fetch the updated user list from the server
        const updatedUsersResponse = await axios.get(
          "/.netlify/functions/getUserList"
        );
        setUsers(updatedUsersResponse.data);
        setInviteEmail("");
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setIsLoading(false);
      }, 1500);
    }
  };

  // Handles deletion of user
  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      setConfirmationModal(false);

      const response = await axios.delete(
        `/.netlify/functions/deleteUser/${userToBeDeleted.user_id}`
      );

      // If response is successful
      if (response.status >= 200 && response.status < 300) {
        // Fetch the updated user list from the server
        const updatedUsersResponse = await axios.get(
          "/.netlify/functions/getUserList"
        );
        setUsers(updatedUsersResponse.data);
        setInviteEmail("");
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setIsLoading(false);
      }, 1500);
    }
  };

  // Handle modal confirmation
  const handleModal = (user) => {
    setUserToBeDeleted(user);
    setConfirmationModal(true);
  };

  // Handles cancellation of model
  const handleModalCancel = () => {
    setUserToBeDeleted({});
    setConfirmationModal(false);
  };

  // Dismiss the error dialog
  const handleDismiss = () => {
    setError(false);
  };

  // Handles invite modal
  const handleInviteModal = () => {
    setInviteModal(true);
  };

  // Handles invite modal close
  const handleCloseInviteModal = () => {
    setInviteModal(false);
    setInviteEmail("");
  };

  // On change event for email
  const handleInviteEmail = (event) => {
    setInviteEmail(event.target.value);

    // Validate email format
    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Progress props={loadingMessage} />
        </>
      ) : (
        <AdminTable
          users={users}
          handleModal={handleModal}
          handleInviteModal={handleInviteModal}
        />
      )}
      <ModalConfirmation
        confirmationModal={confirmationModal}
        handleModalCancel={handleModalCancel}
        handleDeleteUser={handleDeleteUser}
      />
      <InviteModalConfirmation
        handleCloseInviteModal={handleCloseInviteModal}
        handleCreateUser={handleCreateUser}
        inviteModal={inviteModal}
        handleInviteEmail={handleInviteEmail}
        inviteEmail={inviteEmail}
        emailError={emailError}
      />
      <Error error={error} handleDismiss={handleDismiss} />
    </>
  );
}
