import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function FileExceed({ fileExceed, handleCloseExceed }) {
  return (
    <Dialog open={fileExceed}>
      <DialogTitle>{"File size too large"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your background picture exceeds the 4mb limit. Please resize or choose a different picture.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleCloseExceed} variant="outlined">Close</Button>
      </DialogActions>
    </Dialog>
  );
}