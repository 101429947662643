import React from "react";
import { CompactPicker } from "react-color";

export default function ColorPicker({ activeTextField, onChangeComplete }) {
  return (
    <>
      <CompactPicker
        color={activeTextField.color}
        onChangeComplete={(color) => onChangeComplete(color, activeTextField)}
        width="100%"
      />
    </>
  );
}
