import React, { useEffect, useState, useRef } from "react";
import { Stage, Layer } from "react-konva";
import useImage from "use-image";
import Full_Page_Black from "../Templates/Full_Page_Black.png";
import Full_Page_Red from "../Templates/Full_Page_Red.png";
import Medium_Page_Black from "../Templates/Medium_Page_Black.png";
import Medium_Page_Red from "../Templates/Medium_Page_Red.png";
import CanvasBackground from "./CanvasBackground";
import BackgroundImage from "./BackgroundImage";
import Template from "./Template";
import Logo from "./Logo";
import Textfield from "./Textfield";

const templates = {
  Full_Page_Black,
  Full_Page_Red,
  Medium_Page_Black,
  Medium_Page_Red,
};

export default function Canvas({
  textFieldState,
  backgroundImg,
  logoImg,
  logoPlacement,
  templateVal,
  stageRef,
  onTextDragStart,
  onTextDragEnd,
  onTextClick,
  onLogoDragStart,
  onLogoDragEnd,
  isAnimated,
}) {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [background] = useImage(backgroundImg);
  const [logo] = useImage(logoImg);
  const [template] = useImage(templates[templateVal]);

  useEffect(() => {
    const { current } = containerRef;
    const parentAspectRatio = current.offsetWidth / current.offsetHeight;
    const desiredAspectRatio = 16 / 9;
    let width, height;

    if (parentAspectRatio > desiredAspectRatio) {
      // Parent is wider, use height to calculate width
      height = current.offsetHeight;
      width = Math.min(Math.round(height * desiredAspectRatio), 1920);
    } else {
      // Parent is taller or same aspect ratio, use width to calculate height
      width = current.offsetWidth;
      height = Math.min(Math.round(width / desiredAspectRatio), 1080);
    }

    setWidth(width);
    setHeight(height);

    const handleResize = () => {
      const parentAspectRatio = current.offsetWidth / current.offsetHeight;
      let width, height;

      if (parentAspectRatio > desiredAspectRatio) {
        height = current.offsetHeight;
        width = Math.min(Math.round(height * desiredAspectRatio), 1920);
      } else {
        width = current.offsetWidth;
        height = Math.min(Math.round(width / desiredAspectRatio), 1080);
      }

      setWidth(width);
      setHeight(height);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Stage
        width={width}
        height={height}
        ref={stageRef}
        style={{ margin: "auto" }}
      >
        <Layer>
          <CanvasBackground width={width} height={height} />
          <BackgroundImage image={background} width={width} height={height} />
          <Template image={template} width={width} height={height} />
          <Logo
            image={logo}
            width={logoPlacement.width}
            height={logoPlacement.height}
            draggable
            onDragStart={onLogoDragStart}
            onLogoDragEnd={onLogoDragEnd}
            logoPlacement={logoPlacement}
            isAnimated={isAnimated}
          />
          {textFieldState.map((text) =>
            text.value ? (
              <Textfield
                key={text.id}
                text={text}
                onTextDragStart={onTextDragStart}
                onTextDragEnd={onTextDragEnd}
                onTextClick={onTextClick}
                isAnimated={isAnimated}
                width={width}
              />
            ) : null
          )}
        </Layer>
      </Stage>
    </div>
  );
}
