import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function ModalConfirmation({
  confirmationModal,
  handleModalCancel,
  handleDeleteUser,
}) {
  return (
    <Dialog open={confirmationModal} onClose={handleModalCancel}>
      <DialogTitle>{"Delete this user?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This user will be deleted from the app forever. This cannot be undone!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleModalCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteUser}
          variant="contained"
          autoFocus
          color="error"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
