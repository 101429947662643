import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function SubmissionDialog({
  submitModal,
  handleCompanyName,
  companyName,
  publishDate,
  handlePublishDate,
  handleEndDate,
  endDate,
  handleCancel,
  handleSubmission,
  handleSubmissionAnimate,
  textFieldState,
  logoPlacement,
}) {
  const isAnimateText =
    textFieldState && textFieldState.find((item) => item.animate);
  const isAnimateLogo = logoPlacement && logoPlacement.animate;
  const shouldAnimate = isAnimateText || isAnimateLogo;

  return (
    <Dialog open={submitModal}>
      <DialogTitle>{"Submit Newsbreak?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To submit this newsbreak, please enter the required information below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="companyName"
          label="Company Name"
          fullWidth
          variant="outlined"
          sx={{ mt: "2rem" }}
          onChange={handleCompanyName}
          value={companyName}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Publish Date"
            inputFormat="MM/DD/YYYY"
            sx={{ mt: "2rem", width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
            onChange={handlePublishDate}
            value={publishDate}
          />
          <DatePicker
            label="End Date"
            inputFormat="MM/DD/YYYY"
            sx={{ mt: "2rem", width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
            onChange={handleEndDate}
            value={endDate}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={!companyName || !publishDate || !endDate}
          onClick={shouldAnimate ? handleSubmissionAnimate : handleSubmission}
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
