import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function HeaderText({
  handleTextChange,
  textFieldState,
}) {
  return (
    <>
      <Typography sx={{ fontSize: "14px", color: "white", fontWeight: "bold" }}>
        Text
      </Typography>
      {textFieldState.map((textField, index) => (
        <TextField
          key={index}
          name={textField.label}
          label={textField.label}
          value={textField.value}
          variant="filled"
          size="small"
          sx={{ backgroundColor: "white", mt: "2rem" }}
          onChange={handleTextChange}
          multiline
          maxRows={4}
        />
      ))}
    </>
  );
}
