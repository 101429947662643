import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import "@fontsource/crimson-pro/400.css";
import { alpha } from "@mui/system";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";

const adminSettings = [
  {
    menuName: "Home",
    menuLink: "/",
  },
  {
    menuName: "Admin",
    menuLink: "/admin",
  },
  {
    menuName: "Logout",
  },
];

const userSettings = [
  {
    menuName: "Logout",
  },
];

export default function Navigation({
  handleDownload,
  handleEmail,
  handleAnimated,
  textFieldState,
  handleDownloadAnimate,
  logoPlacement,
  isAnimated,
  submitIsLoading,
}) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useAuth0();
  const [isAdmin, setIsAdmin] = useState("");
  const isAnimateText =
    textFieldState && textFieldState.find((item) => item.animate);
  const isAnimateLogo = logoPlacement && logoPlacement.animate;
  const shouldAnimate = isAnimateText || isAnimateLogo;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `/.netlify/functions/getUser?id=${user.sub}`
        );
        setIsAdmin(response.data.app_metadata.role === "Admin");
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchUser();
  }, [user.sub]);

  // Opens user menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Closes user menu
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Handle navigation links
  const handleNavigationMenu = (setting) => {
    if (setting.menuLink) {
      navigate(setting.menuLink);
      handleCloseUserMenu();
    }

    // Handles when logging out
    if (!setting.menuLink) {
      // Closes the menu
      handleCloseUserMenu();

      // Handles logging out
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "none",
        backgroundImage: `linear-gradient(
          270deg, hsla(33, 88%, 58%, 1) 0%, hsla(0, 42%, 19%, 1) 100%
        )`,
      }}
    >
      <CssBaseline />
      <Toolbar>
        <img
          src={`${process.env.PUBLIC_URL}/images/landing_logo.png`}
          alt="logo"
          style={{
            height: "23px",
            width: "23px",
          }}
        />
        <Typography
          variant="h5"
          noWrap
          component="a"
          sx={{
            mr: 2,
            flexGrow: 1,
            fontFamily: "crimson-pro",
            fontWeight: "bold",
            fontSize: "23px",
            color: "white",
            ml: "1rem",
          }}
        >
          SmartSpek
        </Typography>
        {location.pathname !== "/admin" && (
          <>
            {shouldAnimate && (
              <Button
                variant="contained"
                onClick={handleAnimated}
                disabled={isAnimated || submitIsLoading}
                sx={{
                  mr: "1rem",
                  boxShadow: "none",
                  backgroundColor: alpha("#737373", 0.5),
                  textTransform: "capitalize",
                  fontWeight: "500",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: alpha("#737373", 0.4),
                  },
                }}
              >
                <PlayArrowIcon sx={{ mr: "5px" }} /> 3.0s
              </Button>
            )}
            <Button
              variant="contained"
              onClick={shouldAnimate ? handleDownloadAnimate : handleDownload}
              disabled={isAnimated || submitIsLoading}
              sx={{
                mr: "1rem",
                boxShadow: "none",
                backgroundColor: alpha("#737373", 0.5),
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: alpha("#737373", 0.4),
                },
              }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              onClick={handleEmail}
              disabled={isAnimated || submitIsLoading}
              sx={{
                mr: "1rem",
                boxShadow: "none",
                backgroundColor: alpha("#737373", 0.5),
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: alpha("#737373", 0.4),
                },
              }}
            >
              Submit Newsbreak
            </Button>
          </>
        )}
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              disabled={isAnimated || submitIsLoading}
              sx={{ p: 0 }}
            >
              <Avatar />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {isAdmin
              ? adminSettings.map((setting, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleNavigationMenu(setting)}
                  >
                    <Typography textAlign="center">
                      {setting.menuName}
                    </Typography>
                  </MenuItem>
                ))
              : userSettings.map((setting, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleNavigationMenu(setting)}
                  >
                    <Typography textAlign="center">
                      {setting.menuName}
                    </Typography>
                  </MenuItem>
                ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
