import React from "react";
import Stack from "@mui/material/Stack";
import ColorBox from "./ColorBox";
import FontStyle from "./FontStyle";
import FontSize from "./FontSize";
import FontFormat from "./FontFormat";
import ColorPicker from "./ColorPicker";
import TextWidth from "./TextWidth";
// import Animate from "./Animate";
import Divider from "@mui/material/Divider";

export default function UpperToolBox({
  textFieldState,
  onChangeComplete,
  handleColorActive,
  handleFont,
  handleFontSize,
  handleTextFormat,
  handleTextWidth,
  // handleAnimate,
}) {
  const activeTextField =
    textFieldState && textFieldState.find((item) => item.isActive);

  return (
    <>
      {activeTextField && (
        <>
          <Stack direction="row" spacing={3} alignItems="center">
            <ColorBox
              activeTextField={activeTextField}
              handleColorActive={handleColorActive}
            />
            <Divider orientation="vertical" flexItem />
            <FontStyle
              activeTextField={activeTextField}
              handleFont={handleFont}
            />
            <FontSize
              activeTextField={activeTextField}
              handleFontSize={handleFontSize}
            />
            <TextWidth
              activeTextField={activeTextField}
              handleTextWidth={handleTextWidth}
            />
            <Divider orientation="vertical" flexItem />
            <FontFormat
              activeTextField={activeTextField}
              handleTextFormat={handleTextFormat}
            />
            {/* <Divider orientation="vertical" flexItem />
            <Animate
              activeTextField={activeTextField}
              handleAnimate={handleAnimate}
            /> */}
          </Stack>

          {activeTextField.isColorActive && (
            <ColorPicker
              activeTextField={activeTextField}
              onChangeComplete={onChangeComplete}
            />
          )}
        </>
      )}
    </>
  );
}
