import React from "react";
import Box from "@mui/material/Box";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import Typography from "@mui/material/Typography";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import CallToActionOutlinedIcon from "@mui/icons-material/CallToActionOutlined";

export default function SmallSideNav({
  textBtn,
  backgroundBtn,
  logoBtn,
  templateBtn,
  handleClick,
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        name="background"
        onClick={() => handleClick("background")}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "1rem",
          backgroundColor: backgroundBtn ? "#252627" : "#18191b",
          "&:hover": {
            backgroundColor: "#252627",
            cursor: "pointer",
          },
        }}
      >
        <ImageOutlinedIcon fontSize="large" sx={{ color: "white" }} />
        <Typography sx={{ fontSize: "12px", color: "white" }}>
          Background
        </Typography>
      </Box>
      <Box
        name="templates"
        onClick={() => handleClick("templates")}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "1rem 1.5rem",
          backgroundColor: templateBtn ? "#252627" : "#18191b",
          "&:hover": {
            backgroundColor: "#252627",
            cursor: "pointer",
          },
        }}
      >
        <CallToActionOutlinedIcon fontSize="large" sx={{ color: "white" }} />
        <Typography sx={{ fontSize: "12px", color: "white" }}>
          Templates
        </Typography>
      </Box>
      <Box
        name="logo"
        onClick={() => handleClick("logo")}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "1rem 1.5rem",
          backgroundColor: logoBtn ? "#252627" : "#18191b",
          "&:hover": {
            backgroundColor: "#252627",
            cursor: "pointer",
          },
        }}
      >
        <BrandingWatermarkOutlinedIcon
          fontSize="large"
          sx={{ color: "white" }}
        />
        <Typography sx={{ fontSize: "12px", color: "white" }}>Logo</Typography>
      </Box>
      <Box
        onClick={() => handleClick("text")}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "1rem 1.5rem",
          backgroundColor: textBtn ? "#252627" : "#18191b",
          "&:hover": {
            backgroundColor: "#252627",
            cursor: "pointer",
          },
        }}
      >
        <TextFieldsOutlinedIcon fontSize="large" sx={{ color: "white" }} />
        <Typography sx={{ fontSize: "12px", color: "white" }}>Text</Typography>
      </Box>
    </Box>
  );
}
