import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function LogoFileExceed({ logoFileExceed, handleCloseLogoExceed }) {
  return (
    <Dialog open={logoFileExceed}>
      <DialogTitle>{"File size too large"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your logo exceeds the 1mb limit. Please resize or choose a different logo.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ m: "0 1rem 1rem 1rem" }}>
        <Button onClick={handleCloseLogoExceed} variant="outlined">Close</Button>
      </DialogActions>
    </Dialog>
  );
}