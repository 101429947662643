import React, { useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "../Landing/Landing";
import Home from "../Home/Home";
import NotFound from "../NotFound/NotFound";
import Navigation from "../Navigation/Navigation";
import Admin from "../Admin/Admin";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Progress from "../Progress";
import SubmissionDialog from "./SubmissionDialog";
import ErrorDialog from "./ErrorDialog";
import moment from "moment";
import Whammy from "react-whammy";
import {
  textState,
  logoImgState,
  videoCompileLoading,
  loadingMessage,
} from "../Properties";
import { s3 } from "../Aws";

export default function App() {
  const { isAuthenticated, isLoading, error } = useAuth0(); // Auth0
  const stageRef = useRef(null); // Canvas ref
  const [textFieldState, setTextFieldState] = useState(textState);
  const [logoPlacement, setLogoPlacement] = useState(logoImgState);
  const [logoImg, setLogoImg] = useState([]);
  const [submitModal, setSubmitModal] = useState(false); // Modal form before submission
  const [companyName, setCompanyName] = useState(""); // Company name on form
  const [publishDate, setPublishDate] = useState(""); // Publish date on form
  const [endDate, setEndDate] = useState(""); // End date on form
  const [submitIsLoading, setSubmitIsLoading] = useState(false); // isLoading for submission
  const [submitSuccess, setSubmitSuccess] = useState(false); // If submission is successful activate success dialog
  const [submitError, setSubmitError] = useState(false); // If submission failed activate error message
  const [submitErrorMessage, setErrorMessage] = useState(""); // Sets Error Message
  const [isAnimated, setIsAnimated] = useState(false); // Is animated true?
  const [logoFileExceed, setLogoFileExceed] = useState(false); // Is the logo file more than 1mb?

  // Starts recording of canvas for email submission
  const handleSubmissionAnimate = () => {
    setSubmitIsLoading(true);
    setIsAnimated(true);
    setSubmitModal(false);
    const frames = [];
    const intervalId = setInterval(() => {
      const canvas = stageRef.current.getStage().toCanvas({ pixelRatio: 2 });
      frames.push(canvas);

      if (frames.length === 100) {
        clearInterval(intervalId);
        handleStopRecordingEmail(frames);
        setIsAnimated(false);
      }
    }, 50);
  };

  // Stops recording and sends the video via email
  const handleStopRecordingEmail = async (frames) => {
    const video = new Whammy.Video(60);
    for (let i = 0; i < frames.length; i++) {
      video.add(frames[i]);
    }

    video.compile(false, async function (output) {
      // Read the video data as a Blob
      const blob = new Blob([output], { type: "video/webm" });

      // Generate a unique key for the video file
      const key = `${Date.now()}.webm`;

      try {
        // Upload the video to S3
        const params = {
          Bucket: "smartspek",
          Key: key,
          Body: blob,
          ContentType: "video/webm",
        };
        const uploadResponse = await s3.upload(params).promise();
        const videoUrl = uploadResponse.Location;

        // If the S3 upload is successful, then send email
        const emailData = {
          name: "Smart Spek",
          email: process.env.REACT_APP_SMART_SPEK_EMAIL,
          subject: "Smart Spek Newsbreak",
          message: `Attached is a newsbreak from ${companyName}. \nPublish date: ${moment(
            publishDate
          ).format("MM/DD/YYYY")}.\nEnd date: ${moment(endDate).format(
            "MM/DD/YYYY"
          )} \nYou can access the video here: ${videoUrl}`,
        };

        const response = await axios.post(
          "/.netlify/functions/sendEmail",
          emailData
        );

        // If response is successful
        if (response.status >= 200 && response.status < 300) {
          setSubmitIsLoading(false);
          setCompanyName("");
          setPublishDate("");
          setEndDate("");
          setSubmitSuccess(true);
        }
        return response.data;
      } catch (error) {
        setSubmitError(true);
        setSubmitIsLoading(false);
        setCompanyName("");
        setPublishDate("");
        setEndDate("");
      }
    });
  };

  // Handles download of canvas
  const handleDownload = () => {
    const uri = stageRef.current.toDataURL({ pixelRatio: 2 });
    const link = document.createElement("a");
    link.download = "smartspek.png";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handles email of canvas
  const handleSubmission = async () => {
    setSubmitIsLoading(true);

    const uri = stageRef.current.toDataURL({ pixelRatio: 2 });

    // Encode the image data
    const base64EncodedImage = uri.replace(/^data:image\/\w+;base64,/, "");
    const emailData = {
      name: "Smart Spek",
      email: process.env.REACT_APP_SMART_SPEK_EMAIL,
      subject: "Smart Spek Newsbreak",
      message: `Attached is a newsbreak from ${companyName}. \nPublish date: ${publishDate}. \nEnd date: ${endDate}`,
      imageData: base64EncodedImage,
    };

    try {
      // Close the submission dialog
      setSubmitModal(false);

      // Call the api
      const response = await axios.post(
        "/.netlify/functions/sendEmail",
        emailData
      );

      // If response is successful
      if (response.status >= 200 && response.status < 300) {
        setSubmitIsLoading(false);
        setCompanyName("");
        setPublishDate("");
        setEndDate("");
        setSubmitSuccess(true);
      }

      return response.data;
    } catch (error) {
      // Setting timeout so is loading is run for 3 seconds
      setTimeout(() => {
        setSubmitError(true);
        setErrorMessage(error.message);
        setSubmitIsLoading(false);
        setCompanyName("");
        setPublishDate("");
        setEndDate("");
      }, 3000);
    }
  };

  // Handles company name
  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  // Handle publish date
  const handlePublishDate = (value) => {
    setPublishDate(value);
  };

  // Handle end date
  const handleEndDate = (value) => {
    setEndDate(value);
  };

  // Handles cancel of modal
  const handleCancel = () => {
    setSubmitModal(false);
    setCompanyName("");
    setPublishDate("");
    setEndDate("");
  };

  // Handles open of model
  const handleEmail = () => {
    setSubmitModal(true);
  };

  // Handles closing of success modal
  const handleCloseSuccess = () => {
    setSubmitSuccess(false);
  };

  // Handles closing of error modal
  const handleDismiss = () => {
    setSubmitError(false);
    setErrorMessage("");
  };

  // Handles animation preview for 3 seconds
  const handleAnimated = () => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
    }, 3000);
  };

  // Handles the download function if there is animation
  const handleDownloadAnimate = () => {
    handleStartRecording();
  };

  // Starts recording of canvas by taking 100 frames
  const handleStartRecording = () => {
    setSubmitIsLoading(true);
    setIsAnimated(true);
    const frames = [];
    const intervalId = setInterval(() => {
      const canvas = stageRef.current.getStage().toCanvas({ pixelRatio: 2 });
      frames.push(canvas);

      if (frames.length === 100) {
        clearInterval(intervalId);
        handleStopRecording(frames);
        setIsAnimated(false);
      }
    }, 50);
  };

  // Stops recording and compiles the 100 frames into a video for download
  const handleStopRecording = async (frames) => {
    const video = new Whammy.Video(60);
    for (let i = 0; i < frames.length; i++) {
      video.add(frames[i]);
    }

    video.compile(false, function (output) {
      const url = URL.createObjectURL(output);
      const link = document.createElement("a");
      link.download = "my-animation.webm";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSubmitIsLoading(false);
    });
  };

  // Handles text fields on change
  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setTextFieldState((prevFields) =>
      prevFields.map((field) =>
        field.label === name ? { ...field, value: value } : field
      )
    );
  };

  // Handles activation of text box
  const onTextClick = (id) => {
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id
          ? { ...field, isActive: !field.isActive }
          : { ...field, isActive: false }
      )
    );
  };

  // Changes color based on which text field it is
  const onChangeComplete = (color, activeTextField) => {
    const { hex } = color;
    const { id } = activeTextField;
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id ? { ...field, color: hex, isColorActive: false } : field
      )
    );
  };

  // Activates the upper tool box
  const handleColorActive = (activeTextField) => {
    const { id } = activeTextField;
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id
          ? { ...field, isColorActive: !field.isColorActive }
          : field
      )
    );
  };

  // Handles font change
  const handleFont = (event) => {
    const { name, value } = event.target;
    setTextFieldState((prevFields) =>
      prevFields.map((field) =>
        field.label === name ? { ...field, font: value } : field
      )
    );
  };

  // Handle font size change
  const handleFontSize = (event) => {
    const { name, value } = event.target;
    setTextFieldState((prevFields) =>
      prevFields.map((field) =>
        field.label === name ? { ...field, fontSize: value } : field
      )
    );
  };

  // Handle font style change
  const handleTextFormat = (event, newFormats, id) => {
    setTextFieldState((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, fontStyle: newFormats } : field
      )
    );
  };

  // Handle font width
  const handleTextWidth = (event) => {
    const { name, value } = event.target;
    setTextFieldState((prevFields) =>
      prevFields.map((field) =>
        field.label === name ? { ...field, textWidth: value } : field
      )
    );
  }

  // Handles text drag on start
  const onTextDragStart = (event, id) => {
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id ? { ...field, isDragging: true } : field
      )
    );
  };

  // Handles x and y positions on drag end
  const onTextDragEnd = (event, id) => {
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id
          ? {
              ...field,
              xCoordinate: event.target.x(),
              yCoordinate: event.target.y(),
              isDragging: false,
            }
          : field
      )
    );
  };

  // Handles animate selection
  const handleAnimate = (event, id) => {
    const { value } = event.target;
    setTextFieldState((prevState) =>
      prevState.map((field) =>
        field.id === id ? { ...field, animate: value } : field
      )
    );
  };

  // Handles the logo upload file
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];

    // If the file size exceeds the 1mb limit
    if (file.size > 1 * 1024 * 1024) {
      setLogoFileExceed(true);
      return;
    }

    const imgUrl = URL.createObjectURL(file);
    setLogoImg(imgUrl);
  };

  // Closes file exceeds dialog for logo
  const handleCloseLogoExceed = () => {
    setLogoFileExceed(false);
  }

  // Handles the removal of logo image
  const handleRemoveLogo = () => {
    setLogoImg([]);
  };

  // Handles logo drag start
  const onLogoDragStart = () => {
    setLogoPlacement((prevState) => {
      return {
        ...prevState,
        isDragging: true,
      };
    });
  };

  // Handles logo drag end
  const onLogoDragEnd = (event) => {
    setLogoPlacement((prevState) => {
      return {
        ...prevState,
        xCoordinate: event.target.x(),
        yCoordinate: event.target.y(),
        isDragging: false,
      };
    });
  };

  // Handle logo size
  const handleLogoSize = (event) => {
    const { value, name } = event.target;

    setLogoPlacement((prevState) => {
      if (name === "Height") {
        return {
          ...prevState,
          height: value,
        };
      } else if (name === "Width") {
        return {
          ...prevState,
          width: value,
        };
      } else {
        return prevState;
      }
    });
  };

  // Handles selection of logo animation
  const handleLogoAnimate = (event) => {
    const { value } = event.target;
    setLogoPlacement((prevState) => {
      return {
        ...prevState,
        animate: value,
      };
    });
  };

  if (error) {
    return (
      <p>
        There is currently an error with logging in. Please contact
        info@golocaldigitalbillboards.com to report this issue.
      </p>
    );
  }

  if (isLoading) {
    return <Progress props={loadingMessage} />;
  }

  return (
    <>
      {submitIsLoading && <Progress props={videoCompileLoading} />}

      {isAuthenticated && !error && !isLoading && (
        <Navigation
          handleDownload={handleDownload}
          handleEmail={handleEmail}
          handleAnimated={handleAnimated}
          textFieldState={textFieldState}
          handleDownloadAnimate={handleDownloadAnimate}
          logoPlacement={logoPlacement}
          isAnimated={isAnimated}
          submitIsLoading={submitIsLoading}
        />
      )}

      <SubmissionDialog
        submitModal={submitModal}
        handleCompanyName={handleCompanyName}
        companyName={companyName}
        publishDate={publishDate}
        handlePublishDate={handlePublishDate}
        handleEndDate={handleEndDate}
        endDate={endDate}
        handleCancel={handleCancel}
        handleSubmission={handleSubmission}
        handleSubmissionAnimate={handleSubmissionAnimate}
        textFieldState={textFieldState}
        logoPlacement={logoPlacement}
      />

      <ErrorDialog 
        handleDismiss={handleDismiss} 
        submitError={submitError}
        submitErrorMessage={submitErrorMessage}
      />

      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated && !error && !isLoading ? (
              <Home
                textFieldState={textFieldState}
                stageRef={stageRef}
                submitSuccess={submitSuccess}
                handleCloseSuccess={handleCloseSuccess}
                isAnimated={isAnimated}
                handleTextChange={handleTextChange}
                onTextClick={onTextClick}
                onChangeComplete={onChangeComplete}
                handleColorActive={handleColorActive}
                handleFont={handleFont}
                handleFontSize={handleFontSize}
                handleTextFormat={handleTextFormat}
                handleTextWidth={handleTextWidth}
                onTextDragStart={onTextDragStart}
                onTextDragEnd={onTextDragEnd}
                handleAnimate={handleAnimate}
                handleLogoUpload={handleLogoUpload}
                handleRemoveLogo={handleRemoveLogo}
                onLogoDragStart={onLogoDragStart}
                onLogoDragEnd={onLogoDragEnd}
                handleLogoSize={handleLogoSize}
                handleLogoAnimate={handleLogoAnimate}
                logoPlacement={logoPlacement}
                logoImg={logoImg}
                logoFileExceed={logoFileExceed}
                handleCloseLogoExceed={handleCloseLogoExceed}
              />
            ) : (
              <Landing />
            )
          }
        />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
