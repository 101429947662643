import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import "@fontsource/lato/400.css";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
    sx={{
      width: "200px",
      height: "50px",
      backgroundColor: "#F2913D",
      fontSize: "20px",
      fontFamily: "lato",
      color: "black",
      "&:hover": {
        backgroundColor: "#F29E38",
      },
    }}
      variant="contained"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
