import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export default function ColorBox({ activeTextField, handleColorActive }) {
  return (
    <>
      <Tooltip title="Text Color" placement="top-start">
        <Box
          onClick={() => handleColorActive(activeTextField)}
          sx={{
            width: "2rem",
            height: "2rem",
            border: "3px solid grey",
            borderRadius: "4px",
            backgroundColor: activeTextField.color,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Tooltip>
    </>
  );
}
