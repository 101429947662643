import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function NotFound() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: `calc(100vh - 64px)` }}
    >
      <Typography variant="h6">This page does not exist</Typography>
    </Stack>
  );
}
