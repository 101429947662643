import React, { useRef, useEffect, useCallback } from "react";
import { Text, Group } from "react-konva";
import Konva from "konva";

export default function Textfield({
  text,
  onTextDragStart,
  onTextDragEnd,
  onTextClick,
  isAnimated,
  width,
}) {
  const textRef = useRef();

  const textWidthPercent = (text.textWidth / 100) * width;

  // Fade left to right animation
  const fadeRight = useCallback(() => {
    const tween = new Konva.Tween({
      node: textRef.current,
      x: text.xCoordinate,
      opacity: 1,
      duration: 2,
      easing: Konva.Easings.Linear,
    });

    tween.play();
  }, [text.xCoordinate]);

  useEffect(() => {
    if (isAnimated && text.animate === "FLR") {
      fadeRight();
    }
  }, [isAnimated, text.animate, fadeRight]);

  return (
    <Group draggable>
      <Text
        ref={textRef}
        text={text.value}
        fontFamily={text.font}
        fontSize={text.fontSize}
        fontStyle={text.fontStyle}
        width={textWidthPercent}
        wrap="word"
        align="left"
        x={text.xCoordinate}
        y={text.yCoordinate}
        fill={text.color}
        onClick={() => onTextClick(text.id)}
      />
    </Group>
  );
}
